import React from 'react';
import EditorFields from './components/EditorFields/EditorFields';
import EditorViewer from './components/EditorViewer/EditorViewer';
import {Model} from '../models-management/types';

interface Props {
  model?: Model;
  path?: string;
}
const SAMPLE_MODEL_URL = '/media/assets/glbs/sampleModel.glb';
const ModelEditor = ({model, path}: Props) => {
  return (
    <div className='d-flex flex-column h-100'>
      <header className='d-flex align-items-center justify-content-between'>
        <div className='fs-3'>Live Editor</div>
        <div>Mode</div>
      </header>

      <section className='flex-grow-1 '>
        <div className='row h-100'>
          <div className='col-xl-8 col-sm-6 '>
            <EditorViewer path={path || SAMPLE_MODEL_URL} />
          </div>
          <div className='col-xl-4 col-sm-6'>
            <EditorFields />
          </div>
        </div>
      </section>

      <footer>
        <button className='btn btn-primary'>Back</button>
      </footer>
    </div>
  );
};

export default ModelEditor;
