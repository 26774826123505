/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {Stage, Layer, Image, Transformer} from 'react-konva';
import {CanvasTextEditor} from './components/CanvasTextEditor';
import {downloadURI, sleep} from '../../../shared/utils';
import './CanvasEditor.scss';
import useCanvasEditorStore, {CanvasText} from './CanvasEditor.store';
import html2canvas from 'html2canvas';
import {Dialog} from 'primereact/dialog';

const scale = 1; // 0.6;
const width = 540 * scale;
const height = 960 * scale;

interface Props {
  bg?: string;
  onChanged?: (dataURL: string) => void;
}

export const CanvasEditor = ({bg, onChanged}: Props) => {
  const [background, setBackground] = useState(bg || toAbsoluteUrl('/media/video-thumbnail.jpg'));
  const stageRef = React.useRef<any>(null);
  const {reset, texts, addText, deleteText, updateText} = useCanvasEditorStore();
  const [selectedText, setSelectedText] = useState<CanvasText>();
  const [count, setCount] = useState(0);
  const [addingText, setAddingText] = useState(false);

  const trRef = React.useRef<any>();

  const exportImage = () => {
    const uri = stageRef.current.toDataURL();
    downloadURI(uri, 'stage.png');
  };

  const setSelected = async (e: any) => {
    if (!e) {
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
      return;
    }

    const elem = e.currentTarget; //imgRef.current

    trRef.current.nodes([elem]);
    trRef.current.getLayer().batchDraw();

    // setSelectedText(undefined);
    // await sleep(100);
    // setSelectedText(text);
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
    }
  };

  const AddText = () => {
    setAddingText(true);

    setTimeout(() => {
      const newElement = document.createElement('div');
      const body = `<p style="font-size:24px">Add text here...</p>`;
      newElement.innerHTML = body;
      newElement.style.width = 'fit-content';
      newElement.style.background = 'transparent';
      newElement.style.position = 'absolute';
      newElement.style.top = '-1000px';
      document.getElementsByTagName('body')[0].appendChild(newElement);

      html2canvas(newElement, {
        backgroundColor: 'transparent',
      }).then((canvas) => {
        // onSetImage(canvas);
        addText({body: body, image: canvas, x: stageRef.current.width() / 2 - 120 / 2, y: 50});
        setCount((c) => c++);
        // newElement.remove();

        setAddingText(false);
      });
    }, 20);
  };

  useEffect(() => {
    // return () => reset();
  }, []);

  useEffect(() => {
    if (!onChanged) return;

    setSelected(undefined);
    const dataURL = stageRef.current.toDataURL();
    onChanged(dataURL);
  }, [texts]);

  return (
    <div>
      <section className='toolbar mb-5'>
        <button
          className='btn btn-sm btn-primary me-2'
          onClick={() => {
            AddText();
          }}
        >
          {!addingText && <i className='ki-outline ki-plus-square me-2 fs-3'></i>}
          {addingText && <i className='fa  fa-spinner fa-spin me-2 fs-3'></i>}
          Add Text
        </button>

        {/* <button
          className='btn btn-sm btn-light-primary bg-white me-2'
          onClick={() => exportImage()}
        >
          <i className='ki-outline ki-cloud-download me-2 fs-3'></i>
          Download
        </button> */}
      </section>

      <Stage
        style={{
          background: `url('${background}')`,
          backgroundSize: `${width}px ${height}px`,
          backgroundRepeat: 'no-repeat',
          transform: 'scale(0.5) translate(-50%,-50%)',
        }}
        width={width}
        height={height}
        ref={stageRef}
        onMouseDown={(e) => checkDeselect(e)}
      >
        <Layer>
          {texts?.map((text) => (
            <Image
              draggable
              id={`item-${text.id}`}
              onClick={(e) => setSelected(e)}
              onDblClick={() => setSelectedText(text)}
              image={text.image}
              x={text.x || 0}
              y={text.y || 0}
              scaleX={text.scaleX || 1}
              scaleY={text.scaleY || 1}
              rotation={text.rotation || 0}
              onDragEnd={(e) => {
                const {x, y, scaleX, scaleY} = e.target.attrs;

                updateText({
                  ...text,
                  x,
                  y,
                  scaleX: scaleX,
                  scaleY: scaleY,
                });
                console.log(`Moved to`, x, y, e.target.attrs);
              }}
              onTransformEnd={(e) => {
                const {rotation} = e.target.attrs;

                updateText({
                  ...text,
                  rotation: rotation,
                });
                console.log(e);
              }}
            />
          ))}

          {/* {isSelected && ( */}
          <Transformer
            ref={trRef}
            flipEnabled={false}
            resizeEnabled={true}
            keepRatio={true}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize

              // console.log(oldBox, newBox);
              // if (Math.abs(newBox.width) > Math.abs(oldBox.width)) return oldBox;

              return newBox;
            }}
          />
          {/* )} */}
        </Layer>
      </Stage>

      <div className='col-md-6 d-none'>
        <section id='texts-list' className='d-none'>
          <h2>Texts list:</h2>
          <ul>
            {texts?.map((text) => (
              <li>
                {/* <a onClick={() => setSelected(text)}>
                    ({text.id}) {text.body}
                  </a> */}
                <span onClick={() => deleteText(text.id)}> Delete</span>
              </li>
            ))}

            <li>
              <a onClick={() => addText({body: 'New Text'})}>Add new text</a>
            </li>
          </ul>
        </section>

        {/* {selectedText && (
            <div className='card shadow p-5'>
              <header className='d-flex justify-content-between align-items-center'>
                <h2>Edit Text:</h2>
                <button className='btn btn-icon ' onClick={() => setSelectedText(undefined)}>
                  <i className='fa fa-times fs-3'></i>
                </button>
              </header>
              <div>
                <div>
                  <CanvasTextEditor value={selectedText} onChange={(v) => setSelectedText(v)} />
                </div>
              </div>
              <footer className='text-end mt-3'>
                <button className='btn btn-primary btn-sm' onClick={() => updateText(selectedText)}>
                  Save
                </button>
              </footer>
            </div>
          )} */}
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={!!selectedText}
        onHide={() => setSelectedText(undefined)}
      >
        {selectedText && (
          <div className='card shadow p-5'>
            <header className='d-flex justify-content-between align-items-center'>
              <h2>Edit Text:</h2>
              <button className='btn btn-icon ' onClick={() => setSelectedText(undefined)}>
                <i className='fa fa-times fs-3'></i>
              </button>
            </header>
            <div>
              <div>
                <CanvasTextEditor value={selectedText} onChange={(v) => setSelectedText(v)} />
              </div>
            </div>
            <footer className='text-end mt-3 d-flex justify-content-between'>
              <button
                className='btn btn-light-danger btn-sm'
                onClick={() => {
                  deleteText(selectedText.id);
                  setSelectedText(undefined);
                }}
              >
                Delete
              </button>

              <button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  const elem = document.querySelector('.ql-editor') as HTMLElement;
                  elem.style.width = 'fit-content';
                  elem.style.height = 'auto';
                  elem.style.padding = '0';

                  html2canvas(elem, {
                    backgroundColor: 'transparent',
                  }).then((canvas) => {
                    updateText({...selectedText, image: canvas});
                    setSelectedText(undefined);
                    setSelected(undefined);
                  });
                }}
              >
                Ok
              </button>
            </footer>
          </div>
        )}
      </Dialog>

      {/* <img src={toAbsoluteUrl(`/media/video-thumbnail.jpg`)} style={{maxHeight: '80vh'}} alt='' /> */}
    </div>
  );
};
