import {CameraControls, ContactShadows, Environment, useGLTF} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useThemeMode} from '../../../../../_metronic/partials';
import React from 'react';
import {EffectComposer, Outline, Selection} from '@react-three/postprocessing';
import {EditorModel} from './EditorModel';

const MODEL_URL = '/media/3dmodels/glasses-sample.glb';

const EditorViewer = ({path}: any) => {
  const {mode} = useThemeMode();
  const opacity = mode === 'light' ? 0.4 : 0.15;

  return (
    <Canvas
      gl={{antialias: true, toneMappingExposure: 0.55}}
      shadows
      camera={{
        far: 100,
        fov: 30,
        near: 0.1,
      }}
    >
      <Camera />
      <Lights />
      <group>
        {path && <EditorModel path={path} />}
        <ContactShadows position={[0, -0.5, 0]} opacity={opacity} />
        <Environment preset='warehouse' />
      </group>

      {/* content */}
    </Canvas>
  );
};

export default EditorViewer;

export const Camera = () => {
  return (
    <CameraControls
      makeDefault
      // distance={9}
      // azimuthAngle={0}
      // autoRotate
      // autoRotateSpeed={0.1}
      // onStart={cameraManager.onCameraControlsEnter}
      // onEnd={cameraManager.onCameraControlsLeave}
      // minDistance={minDistance}
      // maxDistance={maxDistance}
      // minPolarAngle={minPolarAngle}
      // maxPolarAngle={maxPolarAngle}
    />
  );
};

export const Lights = () => {
  return (
    <>
      <ambientLight intensity={2} />
    </>
  );
};

export const OutlineComponent = ({children}: any) => {
  return (
    <Selection>
      <EffectComposer renderPriority={1} multisampling={8} autoClear={false}>
        <Outline
          selectionLayer={1}
          edgeStrength={2}
          visibleEdgeColor={0xa673ff}
          // kernelSize={KernelSize.VERY_SMALL}
          blur={true}
          xRay={false}
        />
      </EffectComposer>
      {children}
    </Selection>
  );
};
