import axios, {AxiosResponse} from 'axios';
import {AppLocator} from '../../../app-locator';
import {getAuth} from '../auth/core/AuthHelpers';
import {TemplatesFilter} from './vidoes.types';
import {toQueryParams} from '../../../shared/utils';

const API_URL =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_API_URL;

export const create3DExperience = async (data: any) => {
  const res = await axios.post(`${API_URL}/experiences/create`, data);
  return res;
};

export const getMyVideos = async (data: any) => {
  const res = await axios.post(`${API_URL}/videos`);
  return res;
};

export const getSharedVideo = async (id: any) => {
  const res = await axios.get(`${API_URL}/experiences/share/view/${id}`);
  return res;
};

export const getAllVideos = async (filter: Partial<TemplatesFilter>) => {
  const params = toQueryParams(filter);

  const res = await axios.get(`${API_URL}/experiences/view?${params}`);
  return res;
};

export const shareVideo = async (pub_id: string) => {
  return axios
    .get(`${API_URL}/experiences/share/${pub_id}`)
    .then((d: AxiosResponse<any>) => d.data);
};

export const deleteVideo = async (pub_id: string | undefined) => {
  return axios.delete(`${API_URL}/experiences/delete/${pub_id}`).then((d: AxiosResponse<any>) => d);
};

export const submitNewTemplate = async (
  data: any,
  files: File[],
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('name', data.Name);
  formData.append('number_of_models', data.NoOfModels);

  if (files.length > 0)
    files.forEach((file) => {
      formData.append('files', file);
    });

  await axios({
    method: 'POST',
    url: `${API_URL}/templates/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};
export const updateTemplate = async (
  data: any,
  id: any,
  files: File[],
  setProgress?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('id', id);
  data.Name && formData.append('name', data.Name);
  data.NoOfModels && formData.append('number_of_models', data.NoOfModels);

  if (files.length > 0)
    files.forEach((file) => {
      formData.append('files', file);
    });

  await axios({
    method: 'PUT',
    url: `${API_URL}/templates/edit`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuth()?.api_token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 50;

      if (setProgress) setProgress(progress);
    },
  });
};

export const getAllTemplates = async (filter: Partial<TemplatesFilter>) => {
  const params = toQueryParams(filter);
  const res = await axios.get(`${API_URL}/templates/view?${params}`);
  return res;
};
