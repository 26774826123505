import {create} from 'zustand';
import {Video} from '../../vidoes.types';
import {Model} from '../../../models-management/types';

interface IStore {
  reset: () => void;

  videos?: Video[];
  filteredVideos?: Video[];
  setVideos: (videos: Video[]) => void;

  searchKey: string;
  setSearchKey: (key: string) => void;
}

const useVideosPageStore = create<IStore>((set, get) => ({
  videos: [],
  setVideos: (videos) => set({videos: videos, filteredVideos: videos}),

  searchKey: '',
  setSearchKey: (key) => {
    set({
      searchKey: key,
      filteredVideos: get().videos?.filter((v) =>
        v.name.toLocaleLowerCase().includes((key || '').trim().toLocaleLowerCase())
      ),
    });
  },

  reset: () => {
    set({
      videos: [],
      filteredVideos: [],
      searchKey: '',
    });
  },
}));

export default useVideosPageStore;
