import React from 'react';
import {Select} from '@react-three/postprocessing';
import {useGltfParser} from './useGLFTParser';
import useEditorStore from '../../model-editor.store';
import {ModelEditorTabs, modelEditTabs} from '../../model-editor.types';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import * as THREE from 'three';
import {OutlineComponent} from './EditorViewer';
import {EditorUILabels} from './EditorUILabels';

interface Props {
  path: string;
}

export const EditorModel = ({path}: Props) => {
  const {meshDetails} = useGltfParser(path);
  const {selectedTab} = useEditorStore();

  const buildMaterial = (meshName: string, meshDetails: any) => {
    console.log('getMaterialForSelectedDisplayName', meshName, meshDetails);
    const material = new THREE.MeshPhysicalMaterial();
    material.roughness = meshDetails.roughness;
    material.metalness = meshDetails.metalness;

    if (meshDetails.transparency > 0) {
      material.transparent = true;
      material.opacity = 1 - meshDetails.transparency;
    }
    let texture: any = null;

    if (meshDetails.selectedTexture === 'original') {
      material.map = meshDetails.meshes.find((mesh: any) => mesh.name === meshName).material.map;
      material.needsUpdate = true;
    } else if (meshDetails.selectedTexture) {
      const texturePath = `/media/assets/texture/${meshDetails.selectedTexture}.jpg`;
      texture = getTextureByPath(texturePath);
      material.map = texture;
      material.needsUpdate = true;
    }

    if (meshDetails.selectedColor) {
      material.color = new THREE.Color(meshDetails.selectedColor);
    }

    if (meshName.includes('lens')) {
      material.envMap = getTextureByPath('/media/assets/environment/brown_photostudio_02_1k.hdr');
    }

    material.needsUpdate = true;
    return material;
  };

  const getTextureByPath = (path: string) => {
    return new THREE.TextureLoader().load(toAbsoluteUrl(path));
  };

  const getChildMesh = (name: string) => {
    return frameMesh?.children.find((d: any) => d.name === name);
  };

  const frameMesh = meshDetails.find((d) => d.displayName === 'frame')?.meshes[0];
  const leftTempleMesh = getChildMesh('left_temple');
  const rightTempleMesh = getChildMesh('right_temple');
  const leftLenseMesh = getChildMesh('left_lens');
  const rightLenseMesh = getChildMesh('right_lens');

  const transparentMaterial = new THREE.MeshPhysicalMaterial();
  transparentMaterial.transparent = true;
  transparentMaterial.opacity = 0;

  return (
    <OutlineComponent>
      <group>
        {/* the following node is used only to select the frame mesh, the frame is drawn in the next code */}
        <Select
          key='frame'
          enabled={selectedTab === modelEditTabs.find((t) => t.tabName === 'frame')?.index}
        >
          {frameMesh && (
            <mesh
              key={'frame_dummy_mesh'}
              name={frameMesh.name}
              position={frameMesh.position}
              geometry={frameMesh.geometry}
              material={transparentMaterial}
            ></mesh>
          )}
        </Select>

        {/* Visible meshes */}
        {frameMesh && (
          <mesh
            key={'frame'}
            name={frameMesh.name}
            matrix={frameMesh.matrix}
            matrixWorld={frameMesh.matrixWorld}
            position={frameMesh.position}
            geometry={frameMesh.geometry}
            material={buildMaterial(
              'frame',
              meshDetails.find((d) => d.displayName === 'frame')
            )}
          >
            <EditorUILabels
              key={3}
              position={frameMesh.geometry.boundingSphere.center.clone()}
              tab={modelEditTabs.find((d) => d.tabName === 'frame')}
            />

            <Select
              key={'temple'}
              enabled={selectedTab === modelEditTabs.find((t) => t.tabName === 'temple')?.index}
            >
              {leftTempleMesh && (
                <mesh
                  key={'temple'}
                  name={leftTempleMesh.name}
                  matrix={leftTempleMesh.matrix}
                  matrixWorld={leftTempleMesh.matrixWorld}
                  position={leftTempleMesh.position}
                  geometry={leftTempleMesh.geometry}
                  material={buildMaterial(
                    leftTempleMesh.name,
                    meshDetails.find((d) => d.displayName === 'temple')
                  )}
                ></mesh>
              )}
              {rightTempleMesh && (
                <mesh
                  key={3}
                  name={rightTempleMesh.name}
                  matrix={rightTempleMesh.matrix}
                  matrixWorld={rightTempleMesh.matrixWorld}
                  position={rightTempleMesh.position}
                  geometry={rightTempleMesh.geometry}
                  material={buildMaterial(
                    rightTempleMesh.name,
                    meshDetails.find((d) => d.displayName === 'temple')
                  )}
                ></mesh>
              )}

              <EditorUILabels
                key={2}
                position={leftTempleMesh.geometry.boundingSphere.center.clone()}
                tab={modelEditTabs.find((d) => d.tabName === 'temple')}
              />
            </Select>

            <Select
              key={'lense'}
              enabled={selectedTab === modelEditTabs.find((t) => t.tabName === 'lenses')?.index}
            >
              {leftLenseMesh && (
                <mesh
                  key={2}
                  name={leftLenseMesh.name}
                  matrix={leftLenseMesh.matrix}
                  matrixWorld={leftLenseMesh.matrixWorld}
                  position={leftLenseMesh.position}
                  geometry={leftLenseMesh.geometry}
                  material={buildMaterial(
                    leftLenseMesh.name,
                    meshDetails.find((d) => d.displayName === 'lenses')
                  )}
                ></mesh>
              )}
              {rightLenseMesh && (
                <mesh
                  key={3}
                  name={rightLenseMesh.name}
                  matrix={rightLenseMesh.matrix}
                  matrixWorld={rightLenseMesh.matrixWorld}
                  position={rightLenseMesh.position}
                  geometry={rightLenseMesh.geometry}
                  material={buildMaterial(
                    rightLenseMesh.name,
                    meshDetails.find((d) => d.displayName === 'lenses')
                  )}
                ></mesh>
              )}

              <EditorUILabels
                key={1}
                position={leftLenseMesh.geometry.boundingSphere.center.clone()}
                tab={modelEditTabs.find((d) => d.tabName === 'lenses')}
              />
            </Select>
          </mesh>
        )}
      </group>
    </OutlineComponent>
  );
};
