import {useEffect, useRef, useState} from 'react';
import {Model, ModelStatusesNamesEnum} from '../../models-management/types';
import {ModelRendering} from './ModelRendering';
import NewViewer from './NewViewer/NewViewer';

interface ViewerProps {
  model_status_name?: string;
  model_path?: string;
  shared_model?: boolean;
  isSample?: boolean;
  isEmbed?: boolean;
  model: Model;
}
const Viewer3D = ({
  model_path,
  model_status_name,
  shared_model,
  isSample = false,
  isEmbed = false,
  model,
}: ViewerProps) => {
  const isPending = [ModelStatusesNamesEnum.PENDING, ModelStatusesNamesEnum.PROCESSING].includes(
    model_status_name as any
  );

  const [height, setHeight] = useState('100%');
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const {current: container} = refContainer;

    if (container) {
      const h = container.clientWidth * (9 / 16);
      setHeight(`${h}px`);
    } else {
      setHeight('100%');
    }
  }, [refContainer]);

  return (
    <>
      <div
        className='viewer-wrapper flex-center bg-gray-100i 
                     mx-auto rounded bg-white text-muted position-relative card-title'
        ref={refContainer}
        style={{height: height}}
        id='viewer-container'
      >
        {model_path && !isPending && model && (
          <NewViewer path={model_path} model={model} />
          // <ModelRendering
          //   model_path={model_path}
          //   shared_model={shared_model}
          //   isSample={isSample}
          //   isEmbed={isEmbed}
          //   model={model}
          // />
        )}
        {!(model_path && !isPending) && (
          <div className='h-100 flex-center d-flex'>model is not ready!</div>
        )}
      </div>
    </>
  );
};

export default Viewer3D;
