import clsx from 'clsx';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {AppLocator} from '../../../../../../app-locator';
import {FormFieldErrorMessage} from '../../../../../../shared/components/FormFieldErrorMessage';
import {UserModel, useAuth} from '../../../../auth';
import {ApiError} from '../../../../auth/core/apiModels';
import {ModelHeaderLayout} from '../../../../shared/components/ModalLayout';
import {editUser, updateMaxCreditsForUser as RealRequest} from '../../../api/users-api';
import {updateMaxCreditsForUser as FakeRequest} from '../../../api/users-api.fake';
import Authorize from '../../../../auth/Authorize';
import {UserRolesEnum} from '../../../../models-management/types';
import {Dropdown} from 'primereact/dropdown';
import {Organization, Role} from '../../../../app/types';
import {GetRolesByOrg, getRoles} from '../../roles/roles-api';
import {getAllOrganizations} from '../../../api/organizations-api';

interface Props {
  submitComplete: (user?: UserModel) => void;
  user: UserModel | undefined;
  openedFromAccountPage?: boolean;
}

const EditUser = ({submitComplete, user, openedFromAccountPage}: Props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [orgRoles, setOrgRoles] = useState<Role[]>([]);
  const [orgs, setOrgs] = useState<Organization[]>([]);

  const {currentUser} = useAuth();

  const updateMaxCreditsSchema = Yup.object().shape({
    max_credits: Yup.number().min(0, 'Minimum 0 credits').nullable(),
    first_name: Yup.string().min(3),
    last_name: Yup.string().min(3),
    role_id: user?.is_super_admin
      ? Yup.number().nullable()
      : Yup.number().required('User Role is required'),
  });

  const initialValues = {
    max_credits: user?.max_credits,
    api_key: user?.api_key,
    first_name: user?.first_name,
    last_name: user?.last_name,
    company: user?.company,
    role_id: Number(user?.role_id),
    is_org_admin: user?.is_org_admin,
    is_super_admin: user?.is_super_admin,
    org_id: user?.org_id,
    job_title: user?.job_title,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: updateMaxCreditsSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        if (!user || formik.initialValues === values) {
          submitComplete(user);
          return;
        }

        const changed = getChangedValues();

        await editUser(user.id, changed);

        toast.success(`${user.first_name} ${user.last_name} user updated successfully`);

        setSubmitted(true);
        submitComplete({...user, ...changed});
      } catch (error: any) {
        setSubmitting(false);
        setLoading(false);

        const responseData: ApiError | undefined = error?.response?.data;
        if (responseData) setStatus(responseData.msg);
        else setStatus('Failed to update User Info');
      }
    },
  });

  const getChangedValues = () => {
    const values = formik.values;
    const initialValues = formik.initialValues as any;

    return Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        acc[key] = value;
      }

      return acc;
    }, {} as any);
  };

  useEffect(() => {
    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs(orgs);
      };

      getOrgs();
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (formik.values.org_id === undefined) return setOrgRoles([]);

    const getOrgRoles = async () => {
      let roles: Role[] = [];

      if (currentUser?.is_super_admin) roles = await GetRolesByOrg(formik.values.org_id ?? 0);
      else if (currentUser?.is_org_admin) roles = await getRoles();
      else return;

      setOrgRoles(roles);
    };

    getOrgRoles();
  }, [formik.values.org_id]);

  // RENDERING
  return (
    <>
      <ModelHeaderLayout
        title={`Edit user ${user?.first_name} ${user?.last_name}`}
        showCloseButton
        onClose={() => submitComplete()}
      >
        {!!user?.is_super_admin && <div className='badge badge-primary mb-2'>Super Admin</div>}
      </ModelHeaderLayout>

      <main
        className={clsx('modal-body p-5 px-6 scroll', {
          'overlay overlay-block': loading,
        })}
      >
        <div className='d-none text-muted mb-5'>
          Please Enter the new max credits for the user {user?.first_name} {user?.last_name} ,
          current credits is set to {user?.max_credits}
        </div>

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-5 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {/* User Type */}
          {(currentUser?.is_super_admin || currentUser?.is_org_admin) && (
            <fieldset className='fv-row mb-4'>
              <label className='form-label fw-bolder text-dark fs-6 me-3'>User Type</label>

              <div>
                {!!currentUser?.is_super_admin && (
                  <div className='form-check form-check-custom form-check-solid d-inline-block me-8'>
                    <input
                      name='type'
                      className='form-check-input'
                      type='radio'
                      value='3d'
                      defaultChecked={formik.values.is_super_admin}
                      onClick={() => {
                        formik.setFieldValue('is_org_admin', false);
                        formik.setFieldValue('is_super_admin', true);
                      }}
                      id='admin'
                    />
                    <label className='form-check-label' htmlFor='admin'>
                      Super Admin
                    </label>
                  </div>
                )}

                <div className='form-check form-check-custom form-check-solid d-inline-block me-8'>
                  <input
                    name='type'
                    className='form-check-input'
                    type='radio'
                    value='3d'
                    defaultChecked={formik.values.is_org_admin}
                    onClick={() => {
                      formik.setFieldValue('is_org_admin', true);
                      formik.setFieldValue('is_super_admin', false);
                    }}
                    id='org_admin'
                  />
                  <label className='form-check-label' htmlFor='org_admin'>
                    Org Admin
                  </label>
                </div>

                <div className='form-check form-check-custom form-check-solid d-inline-block'>
                  <input
                    name='type'
                    className='form-check-input'
                    type='radio'
                    value='3d'
                    defaultChecked={!formik.values.is_org_admin && !formik.values.is_super_admin}
                    onClick={() => {
                      formik.setFieldValue('is_org_admin', false);
                      formik.setFieldValue('is_super_admin', false);
                    }}
                    id='user'
                  />
                  <label className='form-check-label' htmlFor='user'>
                    User
                  </label>
                </div>
              </div>
            </fieldset>
          )}
          {false && !!currentUser?.is_super_admin && (
            <fieldset className='fv-row mb-4 col-md-6'>
              <div className=''>
                <label className='form-label fw-bolder text-dark fs-6'>Organization</label>

                <Dropdown
                  className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                  value={formik.values.org_id}
                  onChange={(e: any) => formik.setFieldValue('org_id', e.value)}
                  options={orgs}
                  optionLabel='name'
                  optionValue='id'
                  placeholder='Choose organization'
                  showClear
                />
              </div>
            </fieldset>
          )}
          {/* Role */}

          {!formik.values.is_org_admin && !formik.values.is_super_admin && orgRoles.length > 0 && (
            <div className='row'>
              <fieldset className='col-md-6 fv-row mb-4 '>
                <label className='form-label fw-bolder text-dark fs-6 required'>Role</label>
                <Dropdown
                  optionLabel='name'
                  optionValue='id'
                  {...formik.getFieldProps('role_id')}
                  className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
                  value={Number(formik.values.role_id)}
                  options={orgRoles}
                  placeholder='Choose Role'
                  showClear
                />

                {formik.touched.role_id && formik.errors.role_id && (
                  <FormFieldErrorMessage>{formik.errors.role_id}</FormFieldErrorMessage>
                )}
              </fieldset>
            </div>
          )}
          {/* User Fields */}
          <fieldset className='row fv-row mb-4'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
              <input
                placeholder='First Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('first_name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                  },
                  {
                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                  }
                )}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <FormFieldErrorMessage>{formik.errors.first_name}</FormFieldErrorMessage>
              )}
            </div>

            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
              <input
                placeholder='Last Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('last_name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.last_name && formik.errors.last_name,
                  },
                  {
                    'is-valid': formik.touched.last_name && !formik.errors.last_name,
                  }
                )}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <FormFieldErrorMessage>{formik.errors.last_name}</FormFieldErrorMessage>
              )}
            </div>
          </fieldset>
          {/* Company */}
          <fieldset className='row fv-row mb-4'>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Company</label>
              <input
                placeholder='Company'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('company')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.company && formik.errors.company,
                  },
                  {
                    'is-valid': formik.touched.company && !formik.errors.company,
                  }
                )}
              />
              {formik.touched.company && formik.errors.company && (
                <FormFieldErrorMessage>{formik.errors.company}</FormFieldErrorMessage>
              )}
            </div>
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Job Title</label>
              <input
                placeholder='Job title'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('job_title')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.job_title && formik.errors.job_title,
                  },
                  {
                    'is-valid': formik.touched.job_title && !formik.errors.job_title,
                  }
                )}
              />
              {formik.touched.job_title && formik.errors.job_title && (
                <FormFieldErrorMessage>{formik.errors.job_title}</FormFieldErrorMessage>
              )}
            </div>
          </fieldset>
          {currentUser?.is_super_admin && (
            <fieldset className='row fv-row mb-4'>
              <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Max Credits</label>
                <input
                  placeholder='Max Credits'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('max_credits')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.max_credits && formik.errors.max_credits,
                    },
                    {
                      'is-valid': formik.touched.max_credits && !formik.errors.max_credits,
                    }
                  )}
                />
                {formik.touched.max_credits && formik.errors.max_credits && (
                  <FormFieldErrorMessage>{formik.errors.max_credits}</FormFieldErrorMessage>
                )}
              </div>
            </fieldset>
          )}
          {(currentUser?.is_super_admin || currentUser?.is_org_admin) && (
            <fieldset className='row fv-row mb-4'>
              <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Api Key</label>
                <input
                  placeholder='Api Key'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('api_key')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.api_key && formik.errors.api_key,
                    },
                    {
                      'is-valid': formik.touched.api_key && !formik.errors.api_key,
                    }
                  )}
                />

                <div className='text-muted ms-3'>
                  * this field is optional and can be used as your internal user identifier.
                </div>

                {formik.touched.api_key && formik.errors.api_key && (
                  <FormFieldErrorMessage>{formik.errors.api_key}</FormFieldErrorMessage>
                )}
              </div>
            </fieldset>
          )}
        </form>
      </main>

      <footer className='modal-footer p-5 border-top'>
        <button type='button' onClick={() => submitComplete()} className='btn btn-light me-2'>
          Close
        </button>
        <button
          type='button'
          onClick={() => formik.submitForm()}
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </>
  );
};

export default EditUser;
