import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import VTOGlassesViewer from './VTOGlassesViewer';

interface Props {
  model_path?: string;
}
const VTOGlasses = ({model_path}: Props) => {
  return (
    <>
      <div
        className='viewer-wrapper flex-center h-100 bg-gray-100i
                     mx-auto rounded bg-white text-muted position-relative card-title'
      >
        {model_path && <VTOGlassesViewer model_path={model_path} />}
        {!model_path && <div>model path not provided!</div>}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
          onClick={() => window.open('https://vree.ai')}
          title='Made with ♥ by vree.ai'
          className='theme-light-show h-3rem position-absolute '
          style={{left: '10px', bottom: '10px', zIndex: 9999}}
        />
      </div>
    </>
  );
};

export default VTOGlasses;
