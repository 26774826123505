import clsx from 'clsx';
import React, {useEffect, useState} from 'react';

import {getUserCreditInfo as fakeApi} from '../api/models-api.fake';
import {getUserCreditInfo} from '../api/models-api';
import {AppLocator} from '../../../../app-locator';
import {useAuth} from '../../auth';

export const percentForWarningCredit = 80;
export const percentForDangerCredit = 100;
const progressBarLabelBreakpoint = 30; // in percent

const productionMode = AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production';
const getCredits = productionMode ? getUserCreditInfo : fakeApi;

const REFRESH_TIME = 60000;

const TopBarCredit = () => {
  const {currentUser} = useAuth();

  const [creditLimit, setCreditLimit] = useState(currentUser?.max_credits || 0);
  const [currentCredit, setCurrentCredit] = useState(currentUser?.used_credits || 0);
  const percent = () => ((currentCredit - 0) * 100) / (creditLimit - 0);

  const get = async () => {
    const credits = await getCredits();
    setCreditLimit(credits.maxCredits);
    setCurrentCredit(credits.usedCredits);
  };

  useEffect(() => {
    get();

    let interval = setInterval(() => get(), REFRESH_TIME);
    return () => interval && clearInterval(interval);
  }, []);

  return (
    <div className=' d-flex align-items-center flex-shrink-0'>
      <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>Credit Status:</span>

      <div
        className={clsx(
          'progress w-100px w-xl-150px w-xxl-300px h-25px ',
          {'bg-light-danger': percent() >= percentForDangerCredit},
          {
            'bg-light-warning':
              percent() >= percentForWarningCredit && percent() < percentForDangerCredit,
          },
          {'bg-light-success': percent() < percentForWarningCredit}
        )}
      >
        <div
          className={clsx(
            'progress-bar rounded fs-7 fw-bold',
            {'bg-danger': percent() >= percentForDangerCredit},
            {
              'bg-warning':
                percent() >= percentForWarningCredit && percent() < percentForDangerCredit,
            },
            {'bg-success': percent() < percentForWarningCredit}
          )}
          role='progressbar'
          style={{width: `${percent()}%`}}
        >
          {percent() >= progressBarLabelBreakpoint && (
            <div id='text-over-current ' className='text-white'>
              {currentCredit} / {creditLimit}
            </div>
          )}
        </div>

        {percent() < progressBarLabelBreakpoint && (
          <div
            id='text-over-empty'
            className='fs-7 fw-bold mt-1 text-center '
            style={{width: `${100 - percent()}%`}}
          >
            {currentCredit} / {creditLimit}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBarCredit;
