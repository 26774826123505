import {useEffect, useState} from 'react';
import {Permission} from '../../../../app/types';
import {useAuth} from '../../../../auth';
import {useBranding} from '../../../../app/core/BrandingProvider';

export const usePermissionChecker = () => {
  const {currentUser} = useAuth();
  const {organization} = useBranding();

  const hasPermission = (code: string, level: number | undefined = undefined) => {
    if (currentUser?.is_super_admin) return true;

    if (!currentUser || !organization) return false;

    let allowed = true;

    const orgPermission = organization.permissions?.find((d) => d.code === code);
    if (!orgPermission) return false;

    if (level !== undefined && currentUser?.is_org_admin) {
      const pass = passPermissionLevel(code, level);

      return pass;
    }

    if (currentUser?.is_org_admin) return true;

    const userPermission = currentUser.role?.permissions.find((d) => d.code === code);
    if (!userPermission) allowed = false;

    if (level !== undefined) allowed = passPermissionLevel(code, level);

    if (!allowed) return false;
    return true;
  };

  const hasAnyPermission = (codes: string[], level: number | undefined = undefined) => {
    let found = false;
    codes?.forEach((d) => {
      if (hasPermission(d, level)) found = true;
    });
    return found;
  };

  const hasNoPermission = (code: string) => !hasPermission(code);

  const passPermissionLevel = (code: string, level: number) => {
    if (!currentUser || !organization) return false;

    const orgPermission = organization.permissions?.find((d) => d.code === code);

    const userPermission = currentUser.role?.permissions.find((d) => d.code === code);

    const perm = userPermission || orgPermission;

    if (!perm?.has_level) return false;

    if (currentUser.is_org_admin && orgPermission && (orgPermission.level || 1) >= level)
      return true;

    if (currentUser.is_org_admin) return false;

    if (userPermission?.level === undefined || userPermission?.level < level) return false;

    return true;
  };

  const getPermission = (code: string): Permission | undefined => {
    if (currentUser?.is_super_admin) return undefined;

    if (!currentUser || !organization) return undefined;

    const orgPermission = organization.permissions?.find((d) => d.code === code);

    const userPermission = currentUser.role?.permissions.find((d) => d.code === code);

    return userPermission || orgPermission;
  };

  return {
    hasPermission,
    hasNoPermission,
    hasAnyPermission,
    passPermissionLevel,
    getPermission,
  };
};
