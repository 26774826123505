import clsx from 'clsx';
import {Calendar} from 'primereact/calendar';
import React, {useEffect, useState} from 'react';
import {KTSVG} from '../../../../../_metronic/helpers';
import {PermissionLevelEnum} from '../../../app/types';
import HasPermission from '../../../auth/HasPermission';
import {modelStatuses, categories, modelRatingOptions} from '../../../models-management/types';
import {Dropdown} from 'primereact/dropdown';
import {toast} from 'react-toastify';
import Packages from '../../Packages/Packages';
import {Package, PaymentStatusEnum, paymentStatuses} from '../../types';
import usePaymentsStore from '../payments.store';
import {fetchPackages} from '../../billing-api';
import {MenuComponent} from '../../../../../_metronic/assets/ts/components';
import {Tooltip} from 'primereact/tooltip';
import {InputText} from 'primereact/inputtext';
import {UserModel} from '../../../auth';
import {getUsers} from '../../../admin/api/users-api';
import {parseError, sleep} from '../../../../../shared/utils';

const PaymentsFilter = () => {
  const [packages, setPackages] = useState<Package[]>([]);
  const {filters, setFilters} = usePaymentsStore();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);

  const getPackages = async () => {
    try {
      const res = await fetchPackages();

      const packages: Package[] = res.data.packages || [];

      setPackages(packages);
    } catch (error) {
      toast.error('Loading packages failed');
      console.error(error);
    }
  };

  const loadUsers = async () => {
    try {
      setLoadingUsers(true);

      const res: any = await getUsers();
      let _users: UserModel[] = res.data.users || [];
      setUsers(_users);
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    getPackages();
    loadUsers();

    MenuComponent.reinitialization();
  }, []);

  return (
    <>
      {/* Toggle */}
      <button
        type='button'
        className='btn  btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-static='true'
      >
        Filter
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className={clsx(
            'svg-icon-2 ms-2'
            // {'text-primary': state?.filter}
          )}
        />
        {/* Filter */}
      </button>

      {/* Menu */}
      <section className='menu menu-sub menu-sub-dropdown w-400px w-md-425px' data-kt-menu='true'>
        <header className='flex align-items-center justify-content-between px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          <div
            title='Close filters'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            data-kt-menu-dismiss='true'
          >
            <i className='la la-close fs-3'></i>
          </div>
        </header>

        <section className='separator border-gray-200'></section>

        <form className='px-7 py-5' data-kt-user-table-filter='form'>
          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>Packages:</label>

            <Dropdown
              placeholder='Choose Package...'
              optionLabel='name'
              optionValue='id'
              name='package_id'
              id='package_id'
              className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={filters.package_id}
              onChange={(e) => setFilters({package_id: e.target.value})}
              options={categories}
              showClear
            />
          </fieldset>

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>Status:</label>

            <Dropdown
              placeholder='Choose status...'
              optionLabel='name'
              optionValue='id'
              name='status'
              id='status'
              className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={filters.status + ''}
              onChange={(e) => setFilters({status: e.target.value ? +e.target.value : undefined})}
              options={paymentStatuses}
              itemTemplate={(item) => (
                <div className={`badge badge-light-${item?.color}`}>{item?.name}</div>
              )}
              valueTemplate={(item) => (
                <div className={`badge badge-light-${item?.color}`}>{item?.name}&nbsp;</div>
              )}
              showClear
            />
          </fieldset>

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>Transaction ID:</label>

            <InputText
              placeholder='Transaction ID ...'
              name='transaction_id'
              id='transaction_id'
              className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={filters.transaction_id}
              onChange={(e) => setFilters({transaction_id: e.target.value})}
            />
          </fieldset>

          <div className='separator my-4' />

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>User:</label>

            <Dropdown
              loading={loadingUsers}
              placeholder='Choose user...'
              optionLabel='first_name'
              optionValue='id'
              name='user_id'
              id='user_id'
              className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700'
              value={filters.user_id}
              onChange={(e) => setFilters({user_id: e.target.value})}
              options={users}
              virtualScrollerOptions={{itemSize: 38}}
              itemTemplate={(item) => (
                <div className=''>
                  {item?.first_name} {item?.last_name} ({item?.company})
                </div>
              )}
              valueTemplate={(item) => (
                <div className='h-base'>
                  {item && (
                    <>
                      {item?.first_name} {item?.last_name} ({item?.company})
                    </>
                  )}
                  &nbsp;
                </div>
              )}
              showClear
              filter
            />
          </fieldset>

          <div className='separator my-4' />

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>
              Starting From:
              <i className='fa fa-info-circle mx-2 text-blue-400 tooltip-from-date'></i>
            </label>
            <Calendar
              className='w-100 bg-light'
              value={filters.from_date}
              onChange={(e: any) => setFilters({from_date: e.value})}
              placeholder='Select date'
              showTime
              inputClassName='form-select form-select-solid'
              readOnlyInput
              showButtonBar
              stepMinute={5}
            />
            <Tooltip target='.tooltip-from-date' className='text-center'>
              <div>Show you the payments submitted after chosen date</div>
            </Tooltip>
          </fieldset>

          <fieldset className='flex flex align-items-center mb-2'>
            <label className='w-200px form-label fs-6 fw-bold'>
              Ending At:
              <i className='fa fa-info-circle mx-2 text-blue-400 tooltip-to-date'></i>
            </label>
            <Calendar
              className='w-100 bg-light'
              value={filters.to_date}
              onChange={(e: any) => setFilters({to_date: e.value})}
              placeholder='Select date'
              showTime
              inputClassName='form-select form-select-solid'
              readOnlyInput
              showButtonBar
              stepMinute={5}
            />
            <Tooltip target='.tooltip-to-date' className='text-center'>
              <div>Show you the payments submitted before chosen date</div>
            </Tooltip>
          </fieldset>

          <footer className='d-flex justify-content-end'>
            {/* <button
              type='button'
              //   onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button> */}
            <button
              type='button'
              //   onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Ok
            </button>
          </footer>
        </form>
      </section>
    </>
  );
};

export default PaymentsFilter;
