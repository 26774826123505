import {useEffect, useState} from 'react';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {compareArrays, parseError} from '../../../../../shared/utils';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {SampleVideos} from './videos.data';
import VideoCard from './components/VideoCard';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import useVideosPageStore from './videos-page.store';
import {VideosSearch} from './components/VideosSearch';
import {ModelsEmptyPlaceholder} from '../../../models-management/pages/models-list/components/ModelsEmptyPlaceholder';
import {getAllVideos} from '../../vidoes.api';
import {EmptyPlaceholder} from '../../../../../shared/components/EmptyPlaceholder';
import {Video, VideoStatusEnum} from '../../vidoes.types';
import {KTSVG} from '../../../../../_metronic/helpers';
import BlockUi from 'react-block-ui';
import {ModelStatusesEnum} from '../../../models-management/types';

const REFRESH_TIME = 15 * 1000;

const VideosPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {filteredVideos, setVideos, videos, reset} = useVideosPageStore();

  const get = async () => {
    //   if (!id) return;
    try {
      setLoading(true);

      const res = await getAllVideos({});
      const _videos: Video[] = res.data.experiences || [];

      const sorted = _videos.sort((a, b) => {
        if (a.create_date < b.create_date) return 1;
        if (a.create_date > b.create_date) return -1;
        return 0;
      });

      if (videos?.length === 0) setVideos(sorted);

      console.log('compareArrays', videos, sorted);

      if (!checkArraysIntegrity(videos, sorted)) setVideos(sorted);

      setLoading(false);
    } catch (error: any) {
      setError(parseError(error));
      setLoading(false);
    }

    function checkArraysIntegrity(videos?: Video[], sorted?: Video[]) {
      const arr1 =
        videos?.map((d) => {
          return {...d, thumbnail: '', video: ''};
        }) || [];

      const arr2 =
        sorted?.map((d) => {
          return {...d, thumbnail: '', video: ''};
        }) || [];

      let integrity = true;
      if (compareArrays(arr1, arr2)) {
        integrity = true;
      } else {
        console.log('updating array');
        integrity = false;
      }
      return integrity;
    }
  };

  useEffect(() => {
    get();

    return () => reset();
  }, []);

  useEffect(() => {
    // Auto refresh on pending models
    let interval: NodeJS.Timer;

    const hasPending = videos?.find(
      (m) =>
        m.status &&
        [VideoStatusEnum.PROCESSING.toString(), VideoStatusEnum.PENDING.toString()].includes(
          m.status.toString()
        )
    );
    if (hasPending) interval = setInterval(() => get(), REFRESH_TIME);

    return () => interval && clearInterval(interval);
  }, [videos]);

  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: '3D Experiences', path: 'videos', isActive: false}]}>
        My Experiences
      </PageTitle>

      <div className='app-container '>
        <header className='d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex align-items-center'>
            <h2>My Experiences</h2>
            <div className='ms-3 d-flex align-items-center'>
              <VideosSearch />
              <button
                className='btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
                onClick={get}
                title='Refresh models and reset cache'
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className='svg-icon-muted svg-icon-4'
                  />
                </span>
              </button>
            </div>
          </div>
          <div className='tools'>
            <Link
              to={`/dashboard/models?from3DExp=1&status=${ModelStatusesEnum.COMPLETED}`}
              className={clsx('btn btn-info btn-sm fw-bold me-2')}
            >
              Generate 3D Experience
            </Link>
          </div>
        </header>

        <BlockUi tag='div' blocking={loading} message='Loading 3D Experiences ...' keepInView>
          <>
            {filteredVideos?.length === 0 && (
              <EmptyPlaceholder
                label='No experiences found!'
                description='Try generating a new 3D Experience or search with different keywords'
              />
            )}
            <div className='row g-10'>
              {filteredVideos?.map((video) => (
                <div className='col-sm-4 col-lg-3'>
                  <VideoCard key={video.id} video={video} onUpdate={() => get()} />
                </div>
              ))}
            </div>
          </>
        </BlockUi>
      </div>
    </div>
  );
};

export default VideosPage;
