import {useGLTF} from '@react-three/drei';
import {useMemo} from 'react';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import useEditorStore from '../../model-editor.store';
import {AnyObject, modelEditTabs} from '../../model-editor.types';

const SAMPLE_MODEL_URL = '/media/3dmodels/glasses-sample.glb';

export const useGltfParser = (path: string) => {
  const {fields} = useEditorStore();
  const fieldsX = fields as AnyObject;

  const gltf = useGLTF(toAbsoluteUrl(path || SAMPLE_MODEL_URL));

  const meshDetails: any[] = [];

  return useMemo(() => {
    const scene = gltf.scene.clone(true);

    modelEditTabs.forEach((group) => {
      group.meshNode.forEach((node) => {
        const frameMesh = scene.getObjectByName('frame');
        const mesh = frameMesh?.getObjectByName(node);

        console.log(`Draw mesh:`, node, frameMesh, mesh);

        // const mesh = frameMesh?.getObjectByName(node)?.clone(true);
        if (mesh) {
          const storedMeshes = meshDetails.find(
            (meshDetail) => meshDetail.displayName === group.tabName
          );
          if (!storedMeshes) {
            console.log('meshes added', mesh);
            meshDetails.push({
              displayName: group.tabName,
              meshes: [mesh],
              selectedTexture: fieldsX[group.tabName].tex,
              selectedColor: fieldsX[group.tabName].color,
              metalness: fieldsX[group.tabName].metallic,
              roughness: fieldsX[group.tabName].roughness,
              transparency: fieldsX[group.tabName].transparency,
              //   availableTextures: group.availableTextures,
            });
          } else {
            storedMeshes.meshes.push(mesh);
          }
        }
      });
    });

    return {meshDetails};
  }, [fieldsX, gltf.scene, meshDetails]);
};
