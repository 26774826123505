import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core';
import VideosPage from './pages/VideosPage/VideosPage';
import Templates from './pages/Templates/Templates';
import ExperienceWizard from './pages/ExperienceWizard/ExperienceWizard';

const Videos = () => {
  return (
    <Routes>
      <Route path='my-videos' element={<VideosPage />} />
      <Route path='templates' element={<Templates />} />
      {/* <Route
        path='generate'
        element={<ExperienceWizard modelsIds={[]} submitComplete={() => {}} />}
      /> */}

      <Route index element={<Navigate to='my-videos' replace />} />
    </Routes>
  );
};

export default Videos;
