import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import VideoCard from '../VideosPage/components/VideoCard';
import {SampleVideos} from '../VideosPage/videos.data';
import {parseError} from '../../../../../shared/utils';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {Dialog} from 'primereact/dialog';
import VideoDialog from '../VideosPage/components/VideoDialog';
import TemplateForm from './components/TemplateForm';
import clsx from 'clsx';
import {useAuth} from '../../../auth';
import {getAllTemplates} from '../../vidoes.api';
import {Video} from '../../vidoes.types';

const Templates = () => {
  const [showWindow, setShowWindow] = useState(false);
  const {currentUser} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<Video[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const res = await getAllTemplates({});
      setTemplates(res.data?.templates || []);

      setLoading(false);
    } catch (error: any) {
      setError(parseError(error));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle breadcrumbs={[{title: '3D Experiences', path: 'videos', isActive: false}]}>
        Templates
      </PageTitle>

      {loading && <LoadingPlaceholder />}
      {!loading && (
        <div className='app-container container-fluid'>
          <header className='d-flex justify-content-between align-items-center mb-5'>
            <h2>Templates for 3D Experiences</h2>

            <div className='tools'>
              {currentUser?.is_super_admin && (
                <button
                  onClick={() => setShowWindow(true)}
                  className='btn btn-primary btn-sm fw-bold me-2'
                >
                  <span>Add New Template</span>
                </button>
              )}
            </div>
          </header>

          <div className='row g-10'>
            {templates.map((video) => (
              <div className='col-3'>
                <VideoCard key={video.id} video={video} isTemplate onUpdate={fetchData} />
              </div>
            ))}
          </div>
        </div>
      )}

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showWindow}
        onHide={() => setShowWindow(false)}
      >
        <TemplateForm
          submitComplete={(ok) => {
            setShowWindow(false);
            ok && fetchData();
          }}
        />
      </Dialog>
    </div>
  );
};

export default Templates;
