import clsx from 'clsx';
import {Link} from 'react-router-dom';
import FBXARViewer from '../../../../model-viewer/components/FBXARViewer';
import {ARModelPageParameters, Model} from '../../../types';
import useModelStore from '../../single-model/model.store';

interface Props {
  model?: Model;
  parameters: ARModelPageParameters;
}

export const FBXARApp: React.FC<Props> = ({model, parameters}) => {
  const {getNext, getPrevious} = useModelStore();

  const {is_external, is_sample, name, query} = parameters;

  return (
    <>
      <FBXARViewer sku={model?.model_name || ''} model={model} />
      {/* next and prev */}
      {!is_external && !is_sample && (
        <>
          <Link
            className={clsx(
              'position-absolute top-50 left-0 ms-5 cursor-pointer px-3 py-4 my-n10 bg-hover-light-dark rounded bg-dark bg-opacity-25',
              {'d-none': !getPrevious()}
            )}
            style={{zIndex: 999}}
            title='Previous Model'
            to={`?id=${getPrevious()}&is_external=0${query ? '&query=' + query : ''}`}
          >
            <i className='fa fa-chevron-left fs-1 text-primary'></i>
          </Link>

          <Link
            className={clsx(
              'position-absolute top-50 right-0 me-10 cursor-pointer px-3 py-4 my-n10 bg-hover-light-dark rounded bg-dark bg-opacity-25',
              {'d-none': !getNext()}
            )}
            style={{zIndex: 999}}
            title='Next Model'
            to={`?id=${getNext()}&is_external=0${query ? '&query=' + query : ''}`}
          >
            <i className='fa fa-chevron-right fs-1 text-primary'></i>
          </Link>
        </>
      )}

      {/* header */}
      <div className=' position-absolute text-center w-100 pt-1'>
        <div
          className='mx-auto w-fit  text-white  text-center p-1 fs-4 rounded '
          style={{textShadow: '1px 1px #000', background: '#00000010'}}
        >
          SKU: {model?.model_name || name}
        </div>
      </div>
    </>
  );
};
