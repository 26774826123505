import {FC, lazy, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {DashboardWrapper} from '../modules/app/DashboardWrapper';
import Admin from '../modules/admin/Admin';
import {useAuth} from '../modules/auth';
import Account from '../modules/account/Account';
import ReportsPage from '../modules/reports/ReportsPage';
import {RolesList} from '../modules/admin/pages/roles/components/RolesList';
import Roles from '../modules/admin/pages/roles/Roles';
import {usePermissionChecker} from '../modules/admin/pages/roles/hooks/permission-checker';
import Billing from '../modules/billing/Billing';
import Videos from '../modules/videos/Videos';
import Authorize from '../modules/auth/Authorize';
import {PageTitle} from '../../_metronic/layout/core';
import {UserOverviewPage} from '../modules/user-dashboard/UserOverviewPage';
import ModelEditor from '../modules/model-editor/ModelEditor';
import {CanvasEditor} from '../modules/canvas-editor/CanvasEditor';

const PrivateRoutes = () => {
  const Models = lazy(() => import('../modules/models-management/Models'));
  const {currentUser} = useAuth();
  const {hasPermission} = usePermissionChecker();

  const renderLoginRedirects = () => {
    if (currentUser?.is_org_admin || currentUser?.is_super_admin)
      return <Route path='auth/*' element={<Navigate to='/admin' />} />;

    const menuItemsPermissions = [
      {code: 'menu.admin-overview', redirectUrl: '/admin/overview'},
      {code: 'menu.user-overview', redirectUrl: '/dashboard/overview'},
      {code: 'menu.models', redirectUrl: '/dashboard/models'},
      {code: 'menu.samples', redirectUrl: '/dashboard/samples'},
      {code: 'menu.users', redirectUrl: '/admin/users'},
      {code: 'menu.login-activity', redirectUrl: '/admin/login-sessions'},
      {code: 'menu.models-report', redirectUrl: '/reports/models'},
      {code: 'menu.settings', redirectUrl: '/admin/settings'},
      {code: 'models.create', redirectUrl: '/dashboard/create-model?fromMenu=1'},
    ];

    const firstMenu = menuItemsPermissions.find((permission) => hasPermission(permission.code));

    if (firstMenu) return <Route path='auth/*' element={<Navigate to={firstMenu.redirectUrl} />} />;

    return <Route path='auth/*' element={<Navigate to='/empty' />} />;
    // return <Route path='auth/*' element={<Navigate to='/dashboard' />} />;
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Dashboard Redirect to Dashboard after success login/registartion */}

        {renderLoginRedirects()}

        {/* Lazy Modules */}
        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <Models />
            </SuspensedView>
          }
        />

        <Route
          path='admin/*'
          element={
            <SuspensedView>
              <Admin />
            </SuspensedView>
          }
        />

        <Route
          path='account/*'
          element={
            <SuspensedView>
              <Account />
            </SuspensedView>
          }
        />

        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <Roles />
            </SuspensedView>
          }
        />

        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />

        <Route
          path='billing/*'
          element={
            <SuspensedView>
              <Billing />
            </SuspensedView>
          }
        />

        <Route
          path='empty/*'
          element={
            <SuspensedView>
              <div>You did not have any menu permissions! </div>
            </SuspensedView>
          }
        />

        <Route
          path='videos/*'
          element={
            <SuspensedView>
              <Videos />
            </SuspensedView>
          }
        />

        <Route
          path='model-editor/:id'
          element={
            <Authorize redirect>
              <PageTitle breadcrumbs={[]}>Model Editor</PageTitle>
              <ModelEditor />
            </Authorize>
          }
        />

        <Route
          path='canvas-editor'
          element={
            <Authorize redirect>
              <PageTitle breadcrumbs={[]}>Canvas Editor</PageTitle>
              <CanvasEditor />
            </Authorize>
          }
        />

        {currentUser?.is_super_admin || currentUser?.is_org_admin ? (
          <Route path='*' element={<Navigate to='/admin' />} />
        ) : (
          <Route path='*' element={<Navigate to='/dashboard' />} />
        )}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
