import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {Dropdown} from 'primereact/dropdown';
import {Tooltip} from 'primereact/tooltip';
import {Checkbox} from 'primereact/checkbox';
import {toast} from 'react-toastify';
import {ApiError} from '../../../../auth/core/apiModels';
import {GetRolesByOrg, getRoles, insertRole} from '../roles-api';
import {useAuth} from '../../../../auth';
import {useNavigate} from 'react-router';
import {Organization, Role} from '../../../../app/types';
import {getAllOrganizations} from '../../../api/organizations-api';

interface Props {
  onClose: () => void;
  org_id?: number;
}
export function NewRole({onClose, org_id}: Props) {
  const [newRoleText, setNewRoleText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [orgId, setOrgId] = useState<number>();

  const [copyExisting, setCopyExisting] = useState(false);
  const [existingRole, setExistingRole] = useState<number>();
  const [submitting, setSubmitting] = useState(false);
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const {currentUser} = useAuth();
  const navigate = useNavigate();

  const submitNewRole = async () => {
    setLoading(true);
    setSubmitting(true);

    try {
      const addedRole = await insertRole(
        newRoleText,
        org_id ?? currentUser?.org_id ?? 0,
        existingRole
      );
      toast.success('Role successfully added');

      setSubmitting(false);
      setLoading(false);
      onClose();

      setNewRoleText('');

      navigate(`/roles/${addedRole.id}`);
    } catch (error: any) {
      console.error(error);
      setSubmitting(false);

      setLoading(false);

      const responseData: ApiError | undefined = error?.response?.data;
      if (responseData?.msg) setError(responseData?.msg);
      else setError('Failed to submit ');
    }
  };

  const getOrgRoles = async () => {
    let roles: Role[] = [];

    if (currentUser?.is_super_admin) roles = await GetRolesByOrg(orgId ?? 0);
    else if (currentUser?.is_org_admin) roles = await getRoles();
    else return;

    setRoles(roles);
  };

  const getOrgs = async () => {
    const orgs = await getAllOrganizations();
    setOrgs(orgs);
  };

  useEffect(() => {
    if (orgId === undefined) return setRoles([]);

    getOrgRoles();
  }, [orgId]);

  useEffect(() => {
    setOrgId(currentUser?.org_id);
  }, [currentUser]);

  useEffect(() => {
    getOrgs();
  }, []);

  return (
    <ModalLayout
      onClose={() => onClose()}
      showHeader
      showFooter
      showCloseButton
      showOkButton
      okButtonText='Add'
      onOk={() => submitNewRole()}
      loading={loading}
      title={`Add New Role `}
    >
      <fieldset className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Role Name</label>
        <input
          placeholder='Type name'
          type='text'
          autoComplete='off'
          onChange={(e) => {
            setNewRoleText(e.target.value);
          }}
          className={clsx('form-control form-control-lg form-control-solid  mw-400px', {
            'is-invalid': error,
          })}
        />
        {error && <div className='text-danger'>{error}</div>}
      </fieldset>

      <fieldset className='fv-row mb-4'>
        <div className='flex align-items-center'>
          <Checkbox
            inputId='check'
            onChange={(e) => {
              setCopyExisting(Boolean(e.checked));
              setExistingRole(undefined);
              // setOrgId(undefined);
            }}
            checked={copyExisting}
          />
          <label htmlFor='check' className='ml-2'>
            Copy permissions from an existing role
          </label>
        </div>
      </fieldset>

      {copyExisting && (
        <>
          <div className='row'>
            {currentUser?.is_super_admin && (
              <div className='col-6'>
                <fieldset className='fv-row mb-4'>
                  <div className=''>
                    <label className='form-label fw-bolder text-dark fs-6 w-100'>
                      Role Organization
                    </label>

                    <Dropdown
                      className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700 mw-300px'
                      value={orgId}
                      onChange={(e: any) => setOrgId(e.value)}
                      options={orgs}
                      optionLabel='name'
                      optionValue='id'
                      placeholder='Choose organization'
                      showClear
                    />
                  </div>
                </fieldset>
              </div>
            )}

            <div className='col-6'>
              {orgId !== undefined && (
                <fieldset className='fv-row mb-4'>
                  <label className='form-label fw-bolder text-dark fs-6 w-100'>
                    Role
                    <i className='fa fa-info-circle mx-2 text-blue-400 tooltip-from'></i>
                  </label>

                  <Tooltip target='.tooltip-from' className='text-center'>
                    <div>Select a role to copy its permissions into the new created role</div>
                  </Tooltip>

                  <Dropdown
                    className='bg-gray-100 mb-3 mb-lg-0 border-0 w-100 fw-bold text-gray-700 mw-300px'
                    onChange={(e: any) => setExistingRole(e.value)}
                    value={existingRole}
                    options={roles}
                    optionLabel='name'
                    optionValue='id'
                    placeholder='Choose role'
                    showClear
                    filter
                  />
                </fieldset>
              )}
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}
