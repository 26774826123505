import clsx from 'clsx';
import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {generateUserAvatarImage} from '../../../../_metronic/assets/ts/_utils/UserAvatarGenerator';
import {useBranding} from '../../app/core/BrandingProvider';
import {useAuth} from '../../auth';
import useAccountStore from '../account.store';
import {ActionsTemplate} from '../../admin/pages/users/templates/ActionsTemplate';
import Authorize from '../../auth/Authorize';
import HasPermission from '../../auth/HasPermission';
import {usePermissionChecker} from '../../admin/pages/roles/hooks/permission-checker';

export function AccountHeader() {
  const {organization} = useBranding();
  const {user} = useAccountStore();
  const isFBX = ['FBX', 'FBX_USER'].includes(organization?.name || '');
  const {currentUser} = useAuth();
  const {hasPermission} = usePermissionChecker();

  const clickableRole =
    currentUser?.is_super_admin ||
    (currentUser?.is_org_admin && user?.org?.id === currentUser.org_id);

  const selfAccount = currentUser?.id === user?.id;

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img alt='Logo' src={generateUserAvatarImage(user)} />

              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px' />
            </div>
          </div>
          <div className='flex-grow-1'>
            <section className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-900 text-hover-primary fs-2 fw-bold me-1 capitalized'>
                    {user?.first_name} {user?.last_name}
                  </a>
                </div>
                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                  {!clickableRole && (
                    <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      <i className='me-2 fas fa-shield-halved  '></i>

                      {user?.role?.name || user?.role_name}
                    </div>
                  )}

                  {clickableRole && (
                    <Link
                      to={`/roles/${user?.role_id}`}
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <i className='me-2 fas fa-shield-halved  '></i>

                      {user?.role?.name || user?.role_name}
                    </Link>
                  )}

                  <a className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                    <i className='me-2 fas fa-envelope  '></i>

                    {user?.email}
                  </a>
                </div>
              </div>
              <div className='d-flex my-4'>
                {(currentUser?.is_super_admin || currentUser?.is_org_admin) && (
                  <div className='me-0'>{user && <ActionsTemplate user={user} accountView />}</div>
                )}
              </div>
            </section>

            <section className='d-flex pe-8 flex-wrap'>
              <section className='d-flex flex-wrap flex-grow-1'>
                {/* Models */}
                <article className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <i className='fa fa-inbox text-success me-2'></i>

                    <div className='fs-2 fw-bold counted'>{user?.models_count}</div>
                  </div>
                  <div className='fw-semibold fs-6 text-gray-400'>Models</div>
                </article>
              </section>

              {/* Credits */}
              <HasPermission code='misc.credits'>
                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-semibold fs-6 text-gray-400'>Used Credits</span>
                    <span className='fw-bold fs-6'>
                      {user?.used_credits}/{user?.max_credits}
                    </span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{
                        width: `${((user?.used_credits || 0) / (user?.max_credits || 0)) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              </HasPermission>
            </section>
          </div>
        </div>
      </div>

      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold ps-10'>
        <li className='nav-item mt-2'>
          <NavLink
            to={`/account/${user?.id}/overview`}
            className={({isActive}) =>
              clsx('nav-link text-active-primary ms-0 me-10 py-4', {
                active: isActive,
              })
            }
          >
            Overview
          </NavLink>
        </li>

        {false && selfAccount && (
          <li className='nav-item mt-2'>
            <NavLink
              to={`/account/${user?.id}/billing`}
              className={({isActive}) =>
                clsx('nav-link text-active-primary ms-0 me-10 py-4', {
                  active: isActive,
                })
              }
            >
              Billing
            </NavLink>
          </li>
        )}

        {(hasPermission('users.edit-user') || currentUser?.id === user?.id) && (
          <li className='nav-item mt-2'>
            <NavLink
              to={`/account/${user?.id}/settings`}
              className={({isActive}) =>
                clsx('nav-link text-active-primary ms-0 me-10 py-4', {
                  active: isActive,
                })
              }
            >
              Settings
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
